/*body::before {*/
/*    content: 'xs';*/
/*    @apply fixed*/
/*        top-0*/
/*        left-0*/
/*        bg-red*/
/*        text-white*/
/*        px-2*/
/*        py-1*/
/*        z-50;*/
/*}*/
/*@screen sm {*/
/*    body::before {*/
/*        content: 'sm';*/
/*    }*/
/*}*/
/*@screen md {*/
/*    body::before {*/
/*        content: 'md';*/
/*    }*/
/*}*/
/*@screen lg {*/
/*    body::before {*/
/*        content: 'lg';*/
/*    }*/
/*}*/
/*@screen xl {*/
/*    body::before {*/
/*        content: 'xl';*/
/*    }*/
/*}*/
/*@screen 2xl {*/
/*    body::before {*/
/*        content: '2xl';*/
/*    }*/
/*}*/

[class^='icon-'],
[class*=' icon-'] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icomoon';
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    display: inline-block;
    vertical-align: middle;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-pencil::before {
    content: '\e905';
}
.icon-camera::before {
    content: '\e90f';
}
.icon-headphones::before {
    content: '\e910';
}
.icon-bullhorn::before {
    content: '\e91a';
}
.icon-calendar::before {
    content: '\e953';
}
.icon-warning::before {
    content: '\ea07';
}
.icon-facebook2::before {
    content: '\ea91';
}
.icon-spotify::before {
    content: '\ea94';
}
.icon-soundcloud::before {
    content: '\eac3';
}

@tailwind base;
@tailwind components;

.css--show-menu {
    & .css--main-header {
        @apply fixed;
    }

    & .css--main-menu {
        @apply transform-none;
    }

    & .css--menutoggle {
        @apply bg-red
            text-white;
    }
}

.js--click {
    @apply cursor-pointer;
}

.css--box {
    height: 25vw;
    @apply flex
      text-sm
      relative
      font-special
      text-center
      p-5
      border;
}

.css--logo {
    font-size: 4.6vw;
    width: calc(100% - 100px);
}

.css--select {
    max-width: 300px;
    @apply bg-white
        text-black
        mx-1
        my-5
        relative
        font-special
        text-lg
        md:w-full
        md:ml-5;

    & select {
        @apply bg-transparent
            font-special
            text-black
            p-3
            w-full
            appearance-none;
    }
}

.css--bio p {
    @apply my-4;
}

.swiper-slide {
    @apply opacity-50
        transition-opacity
        relative;

    &.swiper-slide-active {
        @apply opacity-100;
    }
}

.swiper-button-prev,
.swiper-button-next {
    @apply bg-none
        relative
        left-auto
        right-auto
        top-auto
        w-8
        h-4
        inline-block
        align-middle
        m-0;

    &::before,
    &::after {
        content: '';
        @apply absolute
            top-1/2;
    }

    &::before {
        margin-top: -2px;
        @apply w-6
            h-1
            bg-black
            left-1;
    }

    &::after {
        border-width: 8px;
        @apply border-black
            border-r-0
            transform
            rotate-180
            -mt-2
            left-0;
        border-bottom-color: transparent;
        border-top-color: transparent;
    }
}

.swiper-button-next {
    &::before {
        @apply left-0;
    }

    &::after {
        @apply left-5
            transform
            rotate-0;
    }
}

.swiper-pagination {
    @apply static
        flex
        items-center
        mx-2
        w-auto;
}

.swiper-pagination-bullet {
    @apply bg-white
        opacity-100
        border-4
        h-2
        w-2
        border-red
        mx-1
        transition-all
        duration-300;

    &.swiper-pagination-bullet-active {
        @apply border;
    }
}

@screen md {
    .css--show-menu {
        &::before {
            content: '';
            @apply bg-black
                fixed
                inset-0
                z-10
                opacity-80;
        }

        & .css--main-header > :first-child {
            @apply block
                bg-black;
        }

        & .css--quickmenu {
            @apply block;
        }
    }

    .css--box {
        height: 12.5vw;
        @apply text-xl;
    }

    .css--logo {
        font-size: 3vw;
        @apply w-1/2;
    }

    .css--frontpage .css--logo {
        @apply w-full;
    }

    .css--dobbeltospalter {
        width: calc(49% - 32px - 0.3rem);
    }

    .css--grid {
        width: calc(32% - 8px - 0.3em);
    }
}

@screen xl {
    .css--box {
        @apply text-2xl;
    }
}

@screen 2xl {
    .css--box {
        height: 200px;
        padding: 50px;
        @apply text-4xl
            leading-normal;
    }
}

@tailwind utilities;
